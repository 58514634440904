import React from 'react';
import Title from '../UI/Title/Title'
import './Experiences.css'
import GIF from '../GIF/GIF'
import inventory from "../../assets/gifs/inventory.gif"
import autonet from '../../assets/gifs/autonet.gif'
import Box from '../UI/ContainerBox/Box'
import Heading from '../UI/ContainerBox/Heading/Heading'
import Description from '../UI/ContainerBox/Description/Description'
import Bullet from '../UI/ContainerBox/Description/Bullets/Bullets'
import NCRlogo from '../../assets/images/NCRlogo.png'
import Zyngalogo from '../../assets/images/ZyngaLogo.png'
import MWlogo from '../../assets/images/MoveworksLogo.png'
import WNLogo from '../../assets/images/whatnot.png'

const experience = (props) => (
    <div id="Experiences">
        <Title>Experience</Title>

        <Box>
            <Heading title="Incoming @ Whatnot">
                January 2023 - April 2023
            </Heading>
            <div className="Company">
                <img src={WNLogo}/>
            </div>
            <div className="DescBox">
            </div>
        </Box>

        <Box>
            <Heading title="Software Engineer @ Moveworks">
                May 2022 - August 2022
            </Heading>
            <div className="Company">
                <img src={MWlogo}/>
            </div>
            <div className="DescBox">
                <Bullet>Spearheaded the design, development and launch of a <span>centralized</span>, <span>scalable</span>, <span>customer-facing</span> alerting system.</Bullet>
                <Bullet>Built a distributed <span>gRPC</span> service using golang, postgres for an alerting system, reducing noise by <span>80%</span></Bullet>
                <Bullet>Developed POCs for the company-wide use of orchestrator systems such as <span>Temporal</span>, <span>Prefect</span> and <span>Dagster</span>.</Bullet>
            </div>
        </Box>
        <Box>
            <Heading title="Software Engineer @ Zynga">
                September 2021 - December 2021
            </Heading>
            <div className="Company">
                <img className="zynga" src={Zyngalogo}/>
            </div>
            <div className="DescBox">
                <Bullet>Spearheaded the <span>AWS EKS</span> migration of the data ingestion pipeline heartbeat service which monitors data loss and duplication using <span>Terraform</span> and <span>Fluentd</span>.</Bullet>
                <Bullet>Developed a custom <span>golang</span> package to enforce logging levels, reducing <span>AWS CloudWatch</span> costs for the service by 55%</Bullet>
                <Bullet>Built an open-source <span>config-driven</span> service in golang to perform actions based on file changes on S3.</Bullet>
                <Bullet>Recovered <span>60,000+</span> data objects per hour by optimizing Splunk dashboard queries, boosting analysis reliability.</Bullet>
            </div>
        </Box>
        <Box>
            <Heading title="Software Developer @ NCR">
                January 2021 - April 2021
            </Heading>
            <div className="Company">
                <img src={NCRlogo}/>
            </div>
            <div className="DescBox">
                <Bullet>Developed microservices using <span>Spring Boot</span> and <span>Spring Webflux</span> to perform millions of ATM transactions
                        securely by integrating it with multiple backend databases and other microservices.</Bullet>
                <Bullet>Leveraged <span>Docker</span> and <span>Helm</span> to edit, deploy docker images on multiple <span>Kubernetes</span> clusters hosted on GCP.</Bullet>
                <Bullet>Enhanced <span>API</span> endpoints by refactoring and optimising code repositories, improving overall efficiency by <span>60%</span>.</Bullet>
            </div>
        </Box>
        <Box>
            <Heading title="Freelance Software Developer">
                April 2020 - December 2020
            </Heading>
            
            <Description>
                <Bullet>Worked closely with customers to collect requirement 
                    specifications for <span>commercial product development</span>.</Bullet>
                <Bullet>Modified existing software to fix coding errors, <span>improve interfaces</span> and <span>overall efficiency</span>.</Bullet>
                <Bullet>Implemented <span>Machine Learning algorithms</span> like <span>Logistic Regression</span>, <span>K Nearest Neighbours</span> along with computer vision algorithms to <span>automate</span> inventory management, increasing efficiency by <span>~ 300%</span>.</Bullet>
            </Description>

            <p className="DemoTitle">Demos</p>
            <div className="Demo">
                <p className="Date">Inventory Management</p>
                <div className="DescBox">
                    <Bullet>A program that uses <span>Computer Vision algorithms</span> to identify and count shoes moving rapidly on a conveyor belt.</Bullet>
                    <Bullet>Improved <span>speed</span> of inventory management by <span>300%.</span></Bullet>
                    <Bullet>Decreased dependency on labour and <span>reduced client's monthly expenses</span> by <span>60%.</span></Bullet>
                </div>
                <GIF source={inventory} height={"400"}/>
            </div>

            <div className="Demo">
                <p className="Date">AutoNET</p>
                <div className="DescBox">
                    <Bullet>An automated <span>AI-as-a-Service</span> that provides end to end <span>Annotation</span>, <span>Training</span>, <span>Validation</span> and <span>Detection</span> of custom objects.</Bullet>
                </div>
                <GIF source={autonet}/>
            </div>
        
        </Box>
    </div>
);

export default experience;