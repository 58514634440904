import React from 'react';
import './About.css';
import Image from '../../assets/images/face.jpeg';
import Title from '../UI/Title/Title'
import Button from '../UI/Button/Button'
import Resume from '../../assets/pdfs/myresume.pdf'

const about = (props) => (
    <div id="About">
        <Title>About Me</Title>
        <div className="Outer">
            <div className="Inner">
                <img className="Image" src={Image} alt=""/>

                <div className="Info">
                    
                    <p className="Text">I'm a Fourth Year<span> Computer Science </span> 
                        Student at the <span>University of Waterloo</span>. 
                        Passionate at programming, I enjoy delving into new concepts of the ever-so dynamic coding world. <span>AI</span> and <span>ML</span> fascinate me, and I find myself constantly 
                        experimenting with random datasets. Currently, I'm exploring the intersection between machine learning and distributed systems.<br></br><span>Looking for New Grad 2024 opportunities</span></p>
                    <Button class="Resume" href={Resume}>View My Resume</Button>
                </div>
                
            </div>
        </div>
        
    </div>
)

export default about;