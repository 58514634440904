import React from 'react';
import Item from './Item/Item'
import './Items.css'

const items = (props) => {

    const scroll = (location) => {
        const anchor = document.querySelector(location)
        if (!anchor) return;
        anchor.scrollIntoView({ behavior: 'smooth'})
    }

    return(
        <ul className="Items">
            <Item clicked={() => scroll('#About')}>about me</Item>
            <Item clicked={() => scroll('#Experiences')}>experience</Item>
            <Item clicked={() => scroll('#Projects')}>projects</Item>
            <Item clicked={() => window.location.replace('mailto:sholla@uwaterloo.ca')}><span className="Talk">let's talk</span></Item>
        </ul>
    );
};

export default items;