import React from 'react';
import './GIF.css'

const gif = (props) => {

    return (
            <img className="Gif" src={props.source} alt=""></img>
    );
};

export default gif;